@import '../../../sass/abstracts/mixins';

.newsletters {
    margin: 0px 5rem;
    &-filters {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        @include respond(phone) {
            justify-content: center;
            margin: none;
        }
    }
    @include respond(phone) {
        margin: inherit;
    }
}

.newsletter {
    display: flex;
    flex-direction: column;

    &--issuedate {
        align-self: flex-end;
        font-weight: bolder;
    }

    &__h3 {
        padding-bottom: 15px;
        text-decoration: underline;
        text-transform: uppercase;
    }

    &__div {
        padding: 10px 0px;

        &--news {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        &--cards {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }

        &--events {
            margin: 10px 0px;
        }

        &--event {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 2px solid transparent;
            border-image: linear-gradient(to right, transparent , lightgray, transparent);
            border-image-slice: 1;

            &-detail {
                padding-bottom: 10px;
                flex-basis: 33.3%;
            }
        }
    }

    &-card {
        cursor: inherit;
        border: 1px solid black;
        // min-height: 40rem;
        // float: left;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;

        @include respond(phone) {
            width: calc(100% - 20px);
        }

        // &--img {
        // }

        &--header {
            text-transform: uppercase;
            text-decoration: underline;
            text-align: center;
            margin: 10px;
            flex-basis: 10%;
        }

        &--description {
            flex-basis: 90%;
            margin: 10px;
            display: flex;
            flex-direction: column;
        }
    }
}