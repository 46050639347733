.btn {
    background-color: white;
    border: 1px solid black;
    border-radius: 10px;
    width: 30rem;
    cursor: pointer;
    align-self: center;
    height: 5rem;

    @include respond(phone) {
        width: 100%;
    }
}