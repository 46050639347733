@import '../../../sass/abstracts/mixins';

.instructors {
    display: flex;
    flex-direction: column;
    justify-content: center;   
}

.instructor {
    display: flex;
    height: 100vh;
    flex-direction: row;
    align-items: center;

    @include respond(tab-port) {
        flex-direction: column;
        justify-content: center;
        height: inherit;
    }
    
    &:not(:last-child) {
        padding-bottom: 4rem;
    }

    &--photo {
        flex-basis: 30%;
        height: 40rem;
        width: 40rem;
    }

    &--info {
        flex-basis: 70%;
        margin: 0px 2rem;

        &__h1 {
            text-transform: uppercase;
        }
    }
}