.select {
    width: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
    

    &__lbl {
        font-size: 1rem;
        padding-left: 4px;
    }

    &__select {
        border: none;
        border-bottom: solid black 1px;
    }
}