@import '../../sass/abstracts/mixins';

.about {
    &-section {
        margin: 3rem;
        display: flex;
        flex-direction: column;
    }

    &-section__div {
        display: flex;
        flex-direction: row;

        @include respond(tab-port) {
            flex-direction: column;
        }

        > div {
            margin: 2rem 2rem;
        }
    }

    &-mission {
        &--desc {
            display: flex;
            flex-direction: column;
            flex-basis: 50%;
            // padding: 0px 2rem 0px 2rem;

            > * {
                margin: 20px 0px;
            }
        }

        &--photos {
            display: flex;
            flex-basis: 50%;
            position: relative;

            @include respond(tab-port) {
                margin: 10px 10px 10px 20px;
            }
        }
    }

    &-info-card-text {
        text-align: start;

        li {
            margin-left: 15px;
        }
    }

    &-grandmaster {
        &--desc {
            display: flex;
            flex-direction: column;
            flex-basis: 50%;
            justify-content: center;
            align-items: center;
        }

        &--details {
            flex-basis: 50%;   

            > * {
                padding-bottom: 10px;
            }
        }
    }

    &-instructors {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    &-card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        margin-bottom: 10px;

        &--photo {
            border-radius: 50%;
            height: 15rem;
            width: 15rem;
            border: solid black 10px;
        }

        &--header {
            color: black;
            text-decoration: underline;
            margin-top: 1rem;
        }

        // &--subheader {
            
        // }

        &--text {
            margin: 2rem;
            text-align: center;
        }
    }
}