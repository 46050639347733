@import '../../../sass/abstracts/mixins';

.info-card {
    background-color: rgba(white, .8);
    font-size: 1.5rem;
    padding: 2.5rem;
    text-align: center;
    border-radius: 3px;
    box-shadow: 0 1.5rem 4rem rgba(black, .15);
    transition: transform .3s;
    display: block;
    width: 30rem;
    margin-bottom: 2rem;
    transition: all .2s;
    margin: 10px;

    &:hover {
        transform: translateY(-1.5rem) scale(1.03);
    }

    @include respond(phone) {
        width: 90%;
    }

    &--container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    &--icon {
        font-size: 4rem !important;
        margin: 10px;
    }

    &--header {
        font-size: 1.6rem;
        font-weight: 700;
        text-transform: uppercase;
        padding-bottom: 10px;
    }
}