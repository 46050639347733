@import '../../../sass/abstracts/mixins';

.header {
    z-index: 5000;
    position: relative;
    background-color: transparent;
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;

    @include respond(tab-port) {
        flex-direction: column;
    }

    @include respond(phone) {
        // height: 6vh;
        padding: 0 10px;
    }

    &__div {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;

        &-title {
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            min-width: 25rem;
            cursor: pointer;
            position: absolute;

            @include respond(tab-port) {
                width: 100%;
            }
        }

        &-desc {
            display: block;

            @include respond(tab-land) {
                display: none !important;
            }
        }

        &-path {
            display: none;
            height: 100%;
            width: 100%;
            padding: 0px 10px 0px 5px;
            align-items: center;

            @include respond(tab-port) {
                display: flex;
                justify-content: center;
            }
        }

        &-img {
            border-radius: 50%;
            height: 5rem;
            padding-left: 10px;
        }

        &--tabs {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            overflow: auto;

            @include respond(tab-port) {
                display: none !important;
            }
        }

        &--tab {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            width: 10rem;

            > * {
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 1;
            }

            &:hover {
                font-weight: bold;
                text-decoration: underline;
                background-color: rgba($color: grey, $alpha: .2);
            }

            &:hover &-menu {
                display: inherit;
            }

            &-menu {
                width: 20rem;
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                top: 100%;
                background-color: lightgrey;
                font-weight: normal;
                display: none;

                &-item {
                    height: 3rem;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    padding: 5px;

                    &:hover {
                        font-weight: bold;
                        text-decoration: underline;
                        background-color: rgba($color: grey, $alpha: .3);
                    }

                    &--selected {
                        font-weight: bold;
                    }
                }
            }
        }

        &--selected {
            font-weight: bold;
            border-bottom: 2px black solid;
        }
    }

    &__tabs {
        color: black;

        @include respond(tab-port) {
            display: none !important;
        }    
    }
}

.header__btn-menu {
    display: none !important;
    color: black;
    padding: 2rem !important;

    @include respond(tab-port) {
        display: inherit !important;
    }
}