@import '../../../sass/abstracts/mixins';

@mixin class-card-image($url) {
    background-image: linear-gradient(to left, #020202, #3f3e3e), url($url);
}

.class-card {
    &--container {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-content: space-around;
        margin-top: 20px;
    }

    &--header {
        background-size: cover;
        background-blend-mode: screen;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
        border-top-left-radius: 3px;
        border-top-right-radius: 3px; 
        height: 23rem;

        &-1 {
            @include class-card-image('../../../img/classes_children.jpeg')
        }

        &-2 {
            @include class-card-image('../../../img/classes_adult.jpeg')
        }

        &-3 {
            @include class-card-image('../../../img/classes_private.jpeg')
        }

        &-4 {
            @include class-card-image('../../../img/classes_seniors.jpeg')
        }

        &-5 {
            @include class-card-image('../../../img/classes_corporate.jpeg')
        }

        &-6 {
            @include class-card-image('../../../img/classes_school.gif')
        }

        &-7 {
            @include class-card-image('../../../img/classes_selfdefense.png')
        }

        &-8 {
            @include class-card-image('../../../img/classes_lawenforcement.png')
        }

        & > h4 {
            font-size: 2.8rem;
            font-weight: 300;
            text-transform: uppercase;
            text-align: right;
            color: #fff;
            position: absolute;
            top: 15rem;
            right: 1rem;
            background-image: linear-gradient(to left, #2b2a2a, #3f3e3e);
        }
    }

    &--descriptors {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        > * {
            text-align: center;
            width: 80%;
            // border-bottom: 1px black solid;
            border-bottom: 2px solid transparent;
            border-image: linear-gradient(to right, transparent , lightgray, transparent);
            border-image-slice: 1;
            margin: 10px;
        }
    }

    &--info {
        text-align: center;
        margin: 2rem;
        
    }
}