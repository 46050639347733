@import '../../../sass/abstracts/mixins';

.navdrawer {
    top: 8vh !important;
    visibility: hidden;
    color: black;

    > * {
        top: 8vh !important;
    }

    @include respond(tab-port) {
        visibility: inherit;
    }

    &-list {
        width: 100vw;

        &-subheader {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;

            &-title {
                padding-top: 1rem;
            }
        }
    }

    &-listitem {
        &-text {
            font-size: 2rem !important;
        }
        
        &-nested {
            padding-left: 24px !important;
        }

        &-selected {
            > * {
                font-weight: bold !important;
            }
            background-color: rgba($color: grey, $alpha: .2) !important;
        }

        &-spacing {
            height: 10rem;
        }
    }
}