@import '../../../sass/abstracts/mixins';

.class {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond(tab-port) {
        margin: inherit;
    }

    &--img {
        width: 60%;
        margin: 2rem;

        @include respond(tab-port) {
            width: 100%;
            padding-right: 0px;
            margin: auto;
        }
    }

    &__div {
        margin: 2rem;
    }
}