@import '../../../sass/abstracts/mixins';

.link-button {
    display:inline-block;
    cursor: pointer;
    border-bottom: 1px black solid;
    text-transform: uppercase;
    transition: all .2s;

    &:hover {
        background-color: lightgray;
        transform: translateY(-3px);
    }

    &--absolute {
        position: absolute;
        bottom: 0;
        right: 0;
        
        @include respond(tab-port) {
            position: relative;
            margin-top: 20px;
            align-self: flex-end;
        }
    }
}