
@import '../../sass/abstracts/variables';
@import '../../sass/abstracts/mixins';

.albums {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 5rem;

    @include respond(phone) {
        justify-content: center;
    }

    &--obj {
        height: 20rem;
        width: 30rem;
        margin: 1rem;
        cursor: pointer;
    }
}

.album {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--preview {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        margin: 2rem;

        &__img {
            height: 20rem;
            width: 30rem;
        }
    }   
}