@import '../../../sass/abstracts/mixins';

.events {
    &-dates {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 2rem;
        align-items: center;

        &--header {
            background-color: lightgray;
            top: 0;
            position: sticky;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &-date {
        border-radius: 50px;
        height: 5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 5px;
        width: 14.286%;
        
        @include respond(phone) {
            flex-direction: column;
            padding: 8px;
            border-radius: 10%;
            height: 8rem;
        }

        &--weekday {
            padding-right: 5px;
        }

        &--selected {
            background-color: darkgray;
        }
    }

    &-list {
        width: 100%;
        background-color: lightgray;

        &--header {
            font-size: 1.75rem;
            margin: 5px;
        }
        
        &--item {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 5rem;
            padding: 0px 10px;
            background-color: white;
            border: 1px gray solid;
            border-top: none;
        }
    }
}