@import '../../../sass/abstracts/mixins';

.obj-viewer {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    

    &--close {
        position: absolute !important;
        top: 0;
        right: 0;
        // margin: 2rem;
        padding: 2rem !important;
        color: white;
    }

    &--obj {
        height: 80vh;
    }
}