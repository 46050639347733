.card {
    position: relative;
    margin: 10px;
    cursor: pointer;
    width: 40rem;
    transform: translateY(-3px);
    box-shadow: 2rem 2rem 2rem rgba(0, 0, 0, 0.2);
    display: inline-block;
    transition: all .2s;

    &:hover {
        transform: translateY(-10px);
    }

    @include respond(phone) {
        width: 100%;
    }
}