@import '../../../sass/abstracts/mixins';

.footer {
    display: flex;
    flex-direction: column;
    padding: 10px 0px 10px 0px;
    background-color: lightgray;
    font-size: 1.2rem;

    &-row {
        padding: 0px 10rem 10px 10rem;

        @include respond(phone) {
            padding: 0px 1rem 10px 1rem;
        }
    }
    
    &-column {
        // flex-basis: 33.33%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        @include respond(phone) {
            padding-bottom: 10px;
        }
    }

    &-information {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        
        @include respond(phone) {
            flex-direction: column;
        }

        &--about {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &__btn {
                margin-left: 10px;
            }
        }
    }

    &-copyright {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        padding: 0px 5rem 10px 5rem;

        @include respond(phone) {
            flex-direction: column;
        }

        &--logo {
            display: flex;
            flex-direction: row;
            flex-basis: 33.33%;
            align-items: center;
            justify-content: flex-start;

            &__img {
                border-radius: 50%;
                margin-right: 5px;
                height: 5rem;
            }

            &__typ {
                font-weight: bold !important;
            }
        }

        &--info {
            justify-content: center;
            flex-basis: 33.33%;
        }

        &--media {
            justify-content: flex-end;
            flex-basis: 33.33%;
        }
    }

    &--address {
        align-items: flex-start;
    }
}