@import '../../sass/abstracts/mixins';
@import '../../sass/abstracts/variables';

.contact {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    
    @include respond(phone) {
        flex-direction: column;
    }

    &-main {
        padding: 10px;

        &__div {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-content: space-between;
            padding: 15px;
            height: 50rem;
            width: 100%;
            background-image: url(../../img/contact_lrg.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            color: $color-black;
            margin-bottom: 20px;

            @include respond(tab-port) {
                height: 35rem;
            }

            @include respond(phone) {
                height: 50rem;
                background-image: url(../../img/contact.jpg);
            }
        }

        &--title {
            display: block;
            font-size: 5rem;
            font-weight: 400;
            text-align: start;
            letter-spacing: 2rem;
            -webkit-animation-name: moveInLeft;
            animation-name: moveInLeft;
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
            padding-bottom: 10px;
        }
    }

    &--container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 0px 2rem;

        @include respond(phone) {
            flex-direction: column;
        }
    }

    &--info {
        display: flex;
        flex-direction: row;
        margin: 1rem 1rem;


        > h2 {
            padding-bottom: 2rem;
        }

        &__container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0px 2rem;
            flex-basis: 40%;
            margin: 10px;
            width: auto;

            @include respond(tab-port) {
                flex-direction: column;
                height: 100%;
            }
            
            &:hover {
                transform: none;
            }
        }

        &__img {
            border-radius: 50%;
            margin-right: 5px;
            height: 15rem;

            &-container {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                padding-right: 2rem;

                @include respond(tab-port) {
                    padding: 0;
                }
            }
        }
    }

    &__a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $color-grey-dark;
    }

    &__btn {
        align-self: flex-end;
        width: 10rem;
        height: 3rem;
    }

    &--container-map {
        flex-basis: 60%;
    }

    &--map {
        height: 50rem;
        width: 100%;
        position: relative;
        overflow: hidden;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;

        max-width: 50rem;
        min-width: 50rem;
        width: 50rem;

        @include respond(phone) {
            max-width: none;
            min-width: 0px;
            width: 100%;
            margin: 2rem;
        }
     }

     &__icon {
         font-size: 3rem !important;
         margin-right: 1rem;
     }
}

.input {
    width: 100%;
    position: relative;

    > input,textarea {
        margin: 1rem;
        border: none;
        border-bottom: 1px solid black;
        width: 100%;

        &:focus {
            border: none;
            border-bottom: 1px solid $color-primary;
            outline: none;

            &::placeholder {
                color: transparent;
            }
        }
    }

    > textarea {
        height: 30rem;
    }
}