@import '../../sass/abstracts/mixins';

.welcome {
    &-main {
        padding: 10px;

        &__div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: space-between;
            padding: 15px;
            height: 50rem;
            background-image: url(../../img/main-lrg.jpeg);
            background-size: cover;
            background-repeat: no-repeat;
            color: whitesmoke;

            @include respond(tab-port) {
                height: 35rem;
            }

            @include respond(phone) {
                height: 50rem;
                background-image: url(../../img/main.jpeg);
            }
        }

        &--title {
            display: block;
            font-size: 5rem;
            font-weight: 400;
            text-align: center;
            letter-spacing: 2rem;
            -webkit-animation-name: moveInLeft;
            animation-name: moveInLeft;
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
            padding-bottom: 10px;
        }

        &--sub {
            display: flex;
            justify-content: center;
            text-align: center;
            font-size: 2rem;
            font-weight: 700;
            letter-spacing: 1rem;
            -webkit-animation: moveInRight 1s ease-out;
            animation: moveInRight 1s ease-out;
        }
    }

    &-desc {
        margin: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &--container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            width: 100%;

            @include respond(tab-port) {
                flex-direction: column;
            }
        }

        &--composition {
            flex-basis: 40%;
            position: relative;
            margin-right: 20px;

            @include respond(tab-port) {
                margin: 0px 0px 20px 0px;
            }
        
            &__photo {
                box-shadow: 0 1.5rem 4rem rgba(black, .4);
                border-radius: 2px;
                position: absolute;
                z-index: 10;
                transition: all .2s;
                outline-offset: 2rem;
        
                &--p1 {
                    left: 0;
                    top: 0rem;
                    width: calc(35% - 10px);
                }
        
                &--p2 {
                    right: 0;
                    top: 2rem;
                    height: 50rem;
                    width: calc(100% - 10px);

                    @include respond(tab-port) {
                        position: relative;
                        width: 100%;
                    }
                }

                @include respond(tab-port) {
                    box-shadow: none;
                }
            }
        }

        &--text {
            position: relative;
            flex-basis: 60%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            height: 52rem;

            @include respond(tab-port) {
                height: auto;
            }
        }
    }

    &-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &--cards {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
            align-content: space-around;
            margin-top: 20px;

            // @include respond(tab-port) {
            //     display: block;
            // }
        }

        &--link {
            align-self: flex-end;
            margin-top: 30px;
        }
    }

    &-classes {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &--link {
            align-self: flex-end;
            margin-top: 30px;
        }
    }
}
