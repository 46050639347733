@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// MEDIA QUERY MANAGER
/*
    0-600px:        Phone
    600-900px:        Tablet Portrait
    900-1200px:        Tablet Landscape
    1200-1800px:    Normal styles apply
    1800+:          Desktop
*/

// @mixin respond-phone {
//     @media (max-width: 600px) {
//         // Allows us to pass a block of code to a mixin
//         @content
//     };
// }

/*
breakpoint argument choices
    - phone
    - tab-port
    - tab-land
    - desktop

    ORDER: Base + typography > general layout > grid > page layout > components
*/
@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        // rem and em are not effected by the root font-size
        // em's are the best option for media queries
        @media only screen and (max-width: 37.5em) { // 600 / 16 = 37.5 (600px)
            // Allows us to pass a block of code to a mixin
            @content
        };
    };
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 56.25em) { @content }; // 900 / 16 = 56.25 (900px)
    };
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) { @content }; // 1200 / 16 = 75 (1200px)
    };
    @if $breakpoint == desktop {
        @media only screen and (min-width: 112.5em) { @content }; // 1800 / 16 = 112.5 (1800px)
    };
}