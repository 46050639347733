

// Basic reset using the universal selector (select each and every element on the page and apply the decorations)
* {
    margin: 0;
    padding: 0;
    // Change the box model so the borders and the paddings are not longer added to the total width and total height we define
    box-sizing: border-box;
}

html {
    // This defines what 1rem is
    font-size: 62.5%; // 1rem = 10px; 10px/16px = 62.5%

    @include respond(tab-land) { // width < 1200?
        font-size: 56.25%; // 1rem = 9px; 9px/16px = 56.25%
    }
    @include respond(tab-port) { // width < 900?
        font-size: 50%; // 1rem = 8px; 8px/16px = 50%
    }
    @include respond(desktop) {
        font-size: 75%; // 1rem = 12px; 12px/16px = 75%
    }
}

body {
    box-sizing: border-box;
    // padding: 3rem;

    @include respond(tab-port) { 
        padding: 0;
    }
}