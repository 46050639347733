@import '../../sass/abstracts/mixins';

.classes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    &-main {
        &__div {
            color: white;
            background-position: top;
            background-image: linear-gradient(to right top, transparent, rgb(53, 53, 53)), url(../../img/classes_main.jpeg);
            height: 90vh;
            margin: 0px 10rem;

            @include respond(tab-port) {
                margin: inherit;
            }

            @include respond(phone) {
                height: 50rem;
            }
        }
    }
}