.faq {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    &--questions {
        display: flex;
        flex-direction: column;
    }

    &--container {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid rgb(167, 143, 143);
        margin: 1rem;
    }

    &--question {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        margin-bottom: 2px;

        &:focus {
            color: red;
        }

        &--icon {
            transition: transform .3s ease-in !important;
        }

        &--closed {
            transform: rotate(0deg);
        }
    
        &--open {
            transform: rotate(45deg);
        }
    }

    &-answer {
        transition: height .3s ease;

        &--closed {
            height: 0px;
            overflow: hidden;
        }

        &-text {
            transition: visibility .1s linear .2s, opacity .1s linear .2s;

            &--open {
                visibility: visible;
                opacity: 1;
            }

            &--closed {
                visibility: hidden;
                opacity: 0;
            }
        }
    }
}