@import '../../../sass/abstracts/mixins';

.grand-master-yang {
    display: flex;
    flex-direction: row;
    align-items: center;

    @include respond(tab-port) {
        flex-direction: column;
    }

    &--main {
        display: flex;
        flex-direction: column;
        align-items: center;;
    }

    &--img {
        height: 35rem;
        width: 40rem;
        padding: 2rem;

        @include respond(tab-port) {
            width: 100%;
        }
    }

    &--txt {
        padding: 2rem;
    }
}