.header {
    &--section {
        font-size: 3.5rem;
        text-transform: uppercase;
        font-weight: 700;
        display: inline-block;
        text-align: center;
        color: $color-black;
        background-image: linear-gradient(to right, #020202, #020202);
        -webkit-background-clip: text;
        letter-spacing: .2rem;
        transition: all .2s;
        padding: 2rem 0px 3rem 0px;
    }
}