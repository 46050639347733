.quote {
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    border-left: solid darkgray 2px;


    &__svg {
        height: 2rem;
    }
}